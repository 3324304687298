<template>
  <div>
    <b-row class="mt-1">



      <b-col cols="12" md="12" lg="4">
        <b-form-group :label="msg('Firstname')" label-for="ticket-company">
          <b-form-input readonly v-model="ticket.firstname"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" lg="4">
        <b-form-group :label="msg('Lastname')" label-for="ticket-company">
          <b-form-input readonly v-model="ticket.lastname"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" lg="4">
        <b-form-group :label="msg('phone')" label-for="ticket-company">
          <b-form-input readonly v-model="ticket.phone"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" lg="4">
        <b-form-group :label="msg('email')" label-for="ticket-company">
          <b-form-input readonly v-model="ticket.email"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="12" lg="4">
        <b-form-group :label="msg('creationdate')" label-for="ticket-company">
          <b-form-input readonly v-model="ticket.creationDate"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="4">

        <b-form-group :label="msg('Status')" label-for="realEstate-branch">
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              v-model="ticket.status"
              :options="allStatuses"
              class="w-100"
              label="text"
              :reduce="(branch) => branch.value"
              @option:selected="onValueUpdated"

          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" lg="12">
        <b-form-group :label="msg('property')" label-for="ticket-company">
          <b-link :href="'/real_estate/edit/'+ticket.propertyId">Link to the property</b-link>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" lg="12">
        <b-form-group :label="msg('Note')" label-for="ticket-company">
          <b-form-textarea readonly v-model="ticket.note"></b-form-textarea>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="mb-2 mt-1">
        <b-form-group :label="msg('Send email to the customer')" label-for="realEstate-name">
            <quill-editor
                spellcheck="false"
                class="realEstate-description"
                v-bind:value="emailToSend"
                @change="onEditorChange"
            />
        </b-form-group>
      </b-col>


    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'ticketManagement',
  data: () => ({
    emailToSend:""
  }),
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),
    ...mapGetters('ticket', ['allTicketStatus']),


    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allStatuses() {
      return Object.values(this.allTicketStatus)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    ticket: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onEditorChange({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.emailToSend === html)
        return;
      this.emailToSend = html;
      this.ticket.emailToSend=this.emailToSend;
      this.onValueUpdated({quill, html, text})
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
